import React, { useState } from 'react';
import '../../scss/typography/typography.scss';
import './contactUs.scss';
import Button from '../UI/Button/Button';
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ContactUs(props) {
    const { t } = useTranslation();
    const [formValue, setFormValue] = useState({
        name: '',
        email: '',
        message: '',
    });

    const handleInput = (e) => {
        const { name, value } = e.target;
        setFormValue({ ...formValue, [name]: value });
    };
    const handleFormSubmit = async (e) => {
        e.preventDefault();
        await fetch('https://itforces.lt/server/public/api/v1/contact-us', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'POST,PATCH,OPTIONS',
            },
            body: JSON.stringify({
                name: formValue.name,
                email: formValue.email,
                message: formValue.message,
            }),
        })
            .then((response) => response)
            .then((data) => {
                if (data.status === 200) {
                    toast.success(t('server_succes'), {
                        hideProgressBar: true,
                        position: 'top-center',
                    });
                    setFormValue({
                        name: '',
                        email: '',
                        message: '',
                    });
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                toast.error(t('server_error'), {
                    hideProgressBar: true,
                    position: 'top-center',
                });
            });
    };

    return (
        <div className="contact-us-container">
            <h3>
                {t('kontaktu-forma-section-title_1')}{' '}
                <span>{t('kontaktu-forma-section-title_2')}</span>
            </h3>
            <div className="contact-us-desc">
                <p>{t('kontaktu-forma-section-description_1')}</p>
                <p>{t('kontaktu-forma-section-description_2')}</p>
            </div>
            <form onSubmit={handleFormSubmit}>
                <div className="name-and-address-container">
                    <div className="input-container">
                        <div className="label">
                            <label htmlFor="name">
                                {t('kontaktu-forma-name')}
                            </label>
                        </div>
                        <input
                            type="text"
                            name="name"
                            value={formValue.name}
                            onChange={handleInput}
                            required
                        ></input>
                    </div>
                    <div className="input-container">
                        <div className="label">
                            <label htmlFor="email">
                                {t('kontaktu-forma-email')}
                            </label>
                        </div>
                        <input
                            type="text"
                            name="email"
                            value={formValue.email}
                            onChange={handleInput}
                            required
                        ></input>
                    </div>
                </div>
                <div className="message-container">
                    <div className="label">
                        <label htmlFor="message">
                            {t('kontaktu-forma-message')}
                        </label>
                    </div>
                    <textarea
                        className="textarea"
                        type="text"
                        name="message"
                        value={formValue.message}
                        onChange={handleInput}
                        rows="10"
                        required
                    ></textarea>
                </div>
                <Button
                    type="submit"
                    buttonText={t('kontaktu-forma-submit')}
                    isFormButton
                />
                <ToastContainer className="foo" />
            </form>
        </div>
    );
}

export default ContactUs;
