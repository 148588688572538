import React from 'react';
import SingleCard from './SingleCard';
import './specialists.scss';
import { useTranslation } from 'react-i18next';

function Specialists() {
    const { t } = useTranslation();

    return (
        <div className="spec-container">
            <div className="spec-title">
                <h2>
                    {t('musu-specialistai-section-title_1')}
                    <span> {t('musu-specialistai-section-title_2')}</span>
                </h2>
            </div>
            <div className="spec-cards">
                <SingleCard number={""} occupation={t('Project managers')} />
                <div className="border"></div>
                <SingleCard number={""} occupation={t('IT Analysts')} />
                <div className="border"></div>
                <SingleCard number={""} occupation={t('UX/UI Designers')} />
                <div className="border"></div>
                <SingleCard number={""} occupation={t('Front-End Developers')} />
                <div className="border"></div>
                <SingleCard number={""} occupation={t('Back-End Developers')} />
                <div className="border"></div>
                <SingleCard number={""} occupation={t('QA Engineers')} />
                <div className="border"></div>
                <SingleCard number={""} occupation={t('Infrastructure Specialists')} />
                <div className="border"></div>
                <SingleCard number={""} occupation={t('IT Staff Recruiters')} />
            </div>
        </div>
    );
}

export default Specialists;
