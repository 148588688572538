import React from 'react';
import './carouselItem.scss';

function CarouselItem({ icon, title, description }) {
    return (
        <div className="single-card-technology">
            <div className="single-card-technology-container">
                <div className="single-card-technology-svg">
                    <img src={icon} alt={title} />
                </div>
                <div className="single-card-technology-title">
                    <h7 className="h7">{title}</h7>
                </div>
                <div className="single-card-technology-description">
                    <h6>{description}</h6>
                </div>
            </div>
        </div>
    );
}

export default CarouselItem;
