import { React, useEffect, useState, useRef } from 'react';
import Select, { components } from 'react-select';
import { Link } from 'react-scroll';
import './navBar.scss';
import enIcon from '../../assets/img/web/flag_en.svg';
import ltIcon from '../../assets/img/web/flag_lt.svg';
import logoWeb from '../../assets/img/web/Desktop_Size_Logo.svg';
import logoTablet from '../../assets/img/tablet/Tablet_Size_Logo.svg';
import logoMobile from '../../assets/img/mobile/Mobile_Size_Logo.svg';
import linkedinLogo from '../../assets/img/mobile/linkedin_modal.svg';
import Button from '../UI/Button/Button';
import { useTranslation } from 'react-i18next';

const languages = [
    { code: 'lt', label: 'LT', icon: ltIcon },
    { code: 'en', label: 'EN', icon: enIcon },
];

const NAV_LINKS = [
    { id: 'Apie', name: 'apie-mus', link: 'about-container', offset: -50 },
    {
        id: 'Specialistai',
        name: 'specialistai',
        link: 'spec-container',
        offset: -150,
    },
    {
        id: 'Technologijos',
        name: 'technologijos',
        link: 'carousel',
        offset: -50,
    },
    { id: 'Projektai', name: 'projektai', link: 'our-projects', offset: -50 },
];

const Option = (props) => (
    <components.Option {...props}>
        <img src={props.data.icon} alt="flag" />
        {props.data.label}
    </components.Option>
);

function NavBar() {
    const { t, i18n } = useTranslation();
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [scrolling, setScrolling] = useState(false);
    const prevScrollY = useRef(0);

    const link = () => {
        window.open('https://www.linkedin.com/company/itforces/', '_blank');
    };

    const [selectedLanguage, setSelectedLanguage] = useState(
        i18n.language === 'lt' ? languages[0] : languages[1]
    );

    const handleChange = (value) => {
        i18n.changeLanguage(value.code);
        setSelectedLanguage(value);
    };

    const filteredLanguages = languages.filter(lang => lang.code !== selectedLanguage.code);

    const SingleValue = ({ children, ...props }) => (
        <components.SingleValue {...props}>
            <img src={selectedLanguage.icon} alt="s-flag" />
            {children}
        </components.SingleValue>
    );

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollY = window.scrollY;
            if (currentScrollY < 32) {
                setScrolling(false);
            } else {
                setScrolling(true);
            }

            prevScrollY.current = currentScrollY;
        };

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, [scrolling]);

    return (
        <nav className={scrolling ? 'main-nav scrolled' : 'main-nav'}>
            <div className="container">
                <a href="/" className="main-nav__logo">
                    <picture>
                        <source
                            srcSet={`${logoMobile}`}
                            media="(max-width: 835px)"
                        />
                        <source
                            srcSet={`${logoTablet}`}
                            media="(max-width: 1439px)"
                        />
                        <img src={`${logoWeb}`} alt="Logo web" />
                    </picture>
                </a>
                <div
                    className={
                        isCollapsed
                            ? 'main-nav__links collapsed'
                            : 'main-nav__links'
                    }
                >
                    <ul className="section">
                        {NAV_LINKS.map(({ id, name, link, offset }) => (
                            <li key={id}>
                                <div className="border"></div>
                                <Link
                                    to={link}
                                    spy={true}
                                    smooth={true}
                                    offset={offset}
                                    onClick={() => {
                                        setIsCollapsed((prev) => !prev);
                                    }}
                                >
                                    <Button
                                        buttonText={t(name)}
                                        isNavTextButton
                                    ></Button>
                                </Link>
                            </li>
                        ))}
                        <div className="border"></div>

                        {/* <!-- Follow linkedin --> */}
                        <div className="follow">
                            <p className="follow-title">{t('mus-sekite')}</p>
                            <a href="/#" className="collapsed-nav__link">
                                <img
                                    src={linkedinLogo}
                                    alt="linkedin"
                                    className="icon"
                                    onClick={link}
                                />
                                <p className="linkedin-text">Linkedin</p>
                            </a>
                        </div>
                    </ul>
                </div>

                <div className="dropdown-and-burger-wrapper">
                    {/* <!-- Language icon dropdown --> */}
                    <div className="dropdown">
                        <Select
                            value={selectedLanguage}
                            options={filteredLanguages} // use filtered languages
                            onChange={handleChange}
                            isSearchable={false}
                            styles={{
                                singleValue: (base) => ({
                                    ...base,
                                    display: 'flex',
                                    alignItems: 'center',
                                    color: 'white',
                                    gap: '4px',
                                }),
                                valueContainer: (base) => ({
                                    ...base,
                                    justifyContent: 'end',
                                    padding: '0',
                                }),
                                indicatorSeparator: (base) => ({
                                    ...base,
                                    display: 'none',
                                }),
                                indicatorsContainer: (base) => ({
                                    ...base,
                                }),
                                control: (base) => ({
                                    ...base,
                                    background: 'transparent',
                                    border: '0',
                                    width: '110px',
                                    boxShadow: 'none',
                                }),
                                container: (base) => ({
                                    ...base,
                                }),
                                menu: (base) => ({
                                    ...base,
                                    background: '#17171f',
                                    margin: '0',
                                }),
                                MenuList: (base) => ({
                                    ...base,
                                    padding: '0',
                                }),
                                option: (base) => ({
                                    ...base,
                                    backgroundColor: 'transparent',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    gap: '8px',
                                }),
                            }}
                            components={{
                                Option,
                                SingleValue,
                            }}
                        />
                    </div>

                    {/* <!-- Button --> */}
                    <Link
                        to="contact-us-container"
                        spy={true}
                        smooth={true}
                        offset={-50}
                    >
                        <Button buttonText={t('susisiekite')} isNavButton />
                    </Link>

                    {/* <!-- Burger --> */}
                    <button
                        type="button"
                        id="burger"
                        className={
                            isCollapsed
                                ? 'main-nav__toggler collapsed'
                                : 'main-nav__toggler'
                        }
                        onClick={() => {
                            setIsCollapsed((prev) => !prev);
                        }}
                    >
                        <label htmlFor="burger"></label>
                    </button>
                </div>
            </div>
        </nav>
    );
}
export default NavBar;
