import { string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import './project-card.scss';

const ProjectCard = ({
    title,
    description,
    altText = '',
    image,
    imageTablet,
    imageMobile,
}) => {
    const { t } = useTranslation();
    return (
        <div
            className={`project-card ${
                title && description ? 'project-card__with-project' : ''
            }`}
        >
            {image && (
                <picture>
                    {imageTablet && (
                        <source
                            srcSet={`${imageTablet}`}
                            media="(min-width: 834px) and (max-width: 1439px)"
                        />
                    )}
                    {imageMobile && (
                        <source
                            media="(max-width: 833px)"
                            srcSet={`${imageMobile}`}
                        />
                    )}
                    <img
                        src={image}
                        alt={altText}
                        className="project-card__image"
                        loading="lazy"
                    />
                </picture>
            )}

            <div className="project-card__text-container">
                <h6>{title ? title : t('cia-gali-buti-jusu-projektas')}</h6>
                {description && <p>{description}</p>}
            </div>
        </div>
    );
};

ProjectCard.propTypes = {
    title: string,
    description: string,
    altText: string,
    image: string,
    imageTablet: string,
    imageMobile: string,
};

export default ProjectCard;
