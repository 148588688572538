import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './multiCarousel.scss';
import CarouselItem from './CarouselItem';
import DesignIconWeb from '../../assets/img/web/technology_design.svg';
import FeIconWeb from '../../assets/img/web/technology_fe.svg';
import BeIconWeb from '../../assets/img/web/technology_be.svg';
import PmIconWeb from '../../assets/img/web/technology_pm.svg';
import { useTranslation } from 'react-i18next';

function MultiCarousel() {
    const { t } = useTranslation();
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1440 },
            items: 3,
        },
        tablet: {
            breakpoint: { max: 1439, min: 834 },
            items: 2.5,
        },
        mobile: {
            breakpoint: { max: 833, min: 769 },
            items: 3,
        },
        mobile1: {
            breakpoint: { max: 768, min: 661 },
            items: 2.5,
        },
        mobile2: {
            breakpoint: { max: 660, min: 541 },
            items: 2,
        },
        mobile3: {
            breakpoint: { max: 540, min: 476 },
            items: 1.8,
        },
        mobile4: {
            breakpoint: { max: 475, min: 0 },
            items: 1.5,
        },
    };
    return (
        <div className="carousel">
            <div className="technology-container">
                <div className="technology-title-container">
                    <h3 className="technology-title">
                        <span className="technology-title-span">
                            {t('musu-technologijos-section-title_1')}{' '}
                        </span>
                        {t('musu-technologijos-section-title_2')}
                    </h3>
                </div>
                <div className="carousel-main-container">
                    <Carousel
                        arrows={false}
                        responsive={responsive}
                        customButtonGroup={<CustomButtonGroup />}
                        infinite={true}
                    >
                        {/* <CarouselItem
                            icon={PmIconWeb}
                            title={t('musu-technologijos-pm-title')}
                            description={t('musu-technologijos-pm')}
                        /> */}
                        <CarouselItem
                            icon={FeIconWeb}
                            title="FRONT-END"
                            description="HTML, CSS, JavaScript, React, Angular, Vue, Next"
                        />
                        <CarouselItem
                            icon={BeIconWeb}
                            title="BACK-END"
                            description="Node.js, Java, Python, PHP, C#, Ruby, Go, .NET, Laravel"
                        />
                        <CarouselItem
                            icon={DesignIconWeb}
                            title="DESIGN"
                            description="Define, Ideate, Design, Testing, UX/UI"
                        />
                    </Carousel>
                    <div className="carousel-gradient"></div>
                </div>
            </div>
        </div>
    );
}

const CustomButtonGroup = ({ next, previous }) => {
    return (
        <div className="custom-button-group">
            <button className="button-left" onClick={() => previous()}>
                <svg
                    className="button-svg"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                >
                    <path d="m12.718 4.707-1.413-1.415L2.585 12l8.72 8.707 1.413-1.415L6.417 13H20v-2H6.416l6.302-6.293z" />
                </svg>
            </button>
            <button className="button-right" onClick={() => next()}>
                <svg
                    className="button-svg"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                >
                    <path d="M11.293 4.707 17.586 11H4v2h13.586l-6.293 6.293 1.414 1.414L21.414 12l-8.707-8.707-1.414 1.414z" />
                </svg>
            </button>
        </div>
    );
};

export default MultiCarousel;
