import React from 'react';
import { Link } from 'react-scroll';
import './footer.scss';
import logo from '../../assets/img/web/small_logo 2.svg';
import linkedinLogo from '../../assets/img/icons/linkedin.svg';
import { useTranslation } from 'react-i18next';

function Footer() {
    const { t } = useTranslation();

    const link = () => {
        window.open('https://www.linkedin.com/company/itforces/', '_blank');
    };

    const currentYear = new Date().getFullYear();

    return (
        <section className="footer">
            <div className="line"></div>
            <div className="footer-container">
                <div className="item-1">
                    <img src={logo} alt="it forces logo" />
                </div>
                <p className="item-2">
                    <Link
                        to="about-container"
                        spy={true}
                        smooth={true}
                        offset={-50}
                    >
                        {t('apie-mus')}
                    </Link>
                </p>
                <p className="item-3">
                    <Link
                        to="spec-container"
                        spy={true}
                        smooth={true}
                        offset={-150}
                    >
                        {t('specialistai')}
                    </Link>
                </p>
                <p className="item-4">
                    <Link to="carousel" spy={true} smooth={true} offset={-50}>
                        {t('technologijos')}
                    </Link>
                </p>
                <p className="item-5">
                    <Link
                        to="our-projects"
                        spy={true}
                        smooth={true}
                        offset={-50}
                    >
                        {t('projektai')}
                    </Link>
                </p>
                <p className="item-6">
                    <Link
                        to="contact-us-container"
                        spy={true}
                        smooth={true}
                        offset={-50}
                    >
                        {t('susisiek-su-mumis')}
                    </Link>
                </p>
                <div className="item-7">
                    <img
                        src={linkedinLogo}
                        alt="linkedin logo"
                        onClick={link}
                    />
                </div>
                <p className="item-0">
                    © All Rights Reserved | {currentYear} IT Forces
                </p>
            </div>
        </section>
    );
}

export default Footer;
