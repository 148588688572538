import PageLayout from './components/Layout/PageLayout';
import NavBar from './components/NavBar/NavBar';
import Hero from './components/Hero/Hero';
import AboutSection from './components/About/AboutSection';
import Specialists from './components/Specialists/Specialists';
import MultiCarousel from './components/MultiCarousel/MultiCarousel';
import OurProjects from './components/OurProjects/index';
import ContactUs from './components/Contact_Us/ContactUs';
import Footer from './components/Footer/Footer';

function App() {
    return (
        <PageLayout>
            <NavBar />
            <Hero />
            <AboutSection />
            <Specialists />
            <MultiCarousel />
            <OurProjects />
            <ContactUs />
            <Footer />
        </PageLayout>
    );
}

export default App;
