import React from 'react';
import './about.scss';
import { useTranslation } from 'react-i18next';

function AboutSection() {
    const { t } = useTranslation();

    return (
        <div className="about-container">
            <div className="about-title-container">
                <h2 className="about-title">
                    <span className="about-title-span">
                        {t('apie-mus-section-title_1')}
                    </span>{' '}
                    {t('apie-mus-section-title_2')}
                </h2>
            </div>
            <div className="about-desc-container">
                <div className="about-desc">
                    <h4 className="about-desc-title">
                        {t('auginame-naujus-talentus-title')}
                    </h4>
                    <p className="about-desc-text">
                        {t('auginame-naujus-talentus-description')}
                    </p>
                </div>
                <div className="about-divider"></div>
                <div className="about-desc">
                    <h4 className="about-desc-title">
                        {t('ispildome-jusu-projektus-title')}
                    </h4>
                    <p className="about-desc-text">
                        {t('ispildome-jusu-projektus-description')}
                    </p>
                </div>
                <div className="about-divider"></div>
                <div className="about-desc">
                    <h4 className="about-desc-title">
                        {t('raskite-pradedanti-specialista-title')}
                    </h4>
                    <p className="about-desc-text">
                        {t('raskite-pradedanti-specialista-description')}
                    </p>
                </div>
            </div>
        </div>
    );
}

export default AboutSection;
