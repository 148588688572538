import { bool, func, string } from 'prop-types';

import styles from './button.module.scss';

const Button = ({
    buttonText,
    onClick,
    isSecondaryButton,
    isNavButton,
    isNavTextButton,
    isFormButton,
    isTextButton,
    href,
}) => {
    const buttonClass = `${styles.button} ${
        isSecondaryButton
            ? styles['button--secondary']
            : isNavButton
            ? styles['button--nav']
            : isNavTextButton
            ? styles['button--nav-text']
            : isFormButton
            ? styles['button--form']
            : isTextButton
            ? styles['button--text-button']
            : styles['button--primary']
    }`;

    if (href)
        return (
            <a href={href} className={styles['button__link']}>
                <div className={styles.button__border}>
                    <button
                        className={buttonClass}
                        onClick={onClick}
                        data={buttonText}
                    >
                        {buttonText}
                    </button>
                    <span
                        className={styles['button__linear-background']}
                    ></span>
                </div>
            </a>
        );

    return (
        <div className={styles.button__border}>
            <button className={buttonClass} onClick={onClick} data={buttonText}>
                {buttonText}
            </button>
            <span className={styles['button__linear-background']}></span>
        </div>
    );
};

Button.propTypes = {
    buttonText: string.isRequired,
    onClick: func,
    isSecondaryButton: bool,
    isNavButton: bool,
    isNavTextButton: bool,
    isFormButton: bool,
    isTextButton: bool,
    href: string,
};

export default Button;
