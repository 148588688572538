import React from 'react';

function SingleCard({ number, occupation }) {
    return (
        <div className="single-card">
            <h3>{number}</h3>
            <p>{occupation}</p>
        </div>
    );
}

export default SingleCard;
