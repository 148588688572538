import React from 'react';
import { Link } from 'react-scroll';
import './hero.scss';
import HeroWeb from '../../assets/img/web/img_hero.svg';
import HeroTablet from '../../assets/img/tablet/img_hero.svg';
import HeroMobile from '../../assets/img/mobile/img_hero.svg';
import Button from '../UI/Button/Button';
import { useTranslation } from 'react-i18next';

function Hero() {
    const { t } = useTranslation();

    return (
        <div className="hero-container">
            <div className="hero-title-container">
                <div className="hero-title">
                    <h3>{t('hero-title')}</h3>
                </div>
                <p className="hero-title-description">
                    {t('hero-title-description')}
                </p>
                <div className="hero-title-button-container">
                    <Link
                        to="contact-us-container"
                        spy={true}
                        smooth={true}
                        offset={-50}
                    >
                        <Button buttonText={t('susisiek-su-mumis')} />
                    </Link>
                    {/* <Link
                        to="our-projects"
                        spy={true}
                        smooth={true}
                        offset={-50}
                    >
                        <Button
                            buttonText={t('musu-projektai-btn')}
                            isSecondaryButton
                        />
                    </Link> */}
                </div>
            </div>
            <div className="hero-title-picture">
                <picture>
                    <source
                        srcSet={`${HeroMobile}`}
                        media="(max-width: 834px)"
                    />
                    <source
                        srcSet={`${HeroTablet}`}
                        media="(max-width: 1100px)"
                    />
                    <img src={`${HeroWeb}`} alt="Hero Pic" />
                </picture>
            </div>
        </div>
    );
}

export default Hero;
